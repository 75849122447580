/* eslint-disable camelcase */
import React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';

import Page from '../layout/Page';
import Header from '../components/common/Header';
import Paragraph from '../components/common/Paragraph';
import Section from '../components/common/Section';

import { Column } from '../components/Column';
import { ColumnWrapper } from '../page-styles/etudes-marches.styled';
import { COLORS, COLUMN_ICON_SIZE } from '../constants.styles';

export default ({ data }) => {
  const featureColumns = get(data, 'prismicStudyMarketsPage.data.features.document.data');
  const prospectiveColumns = get(data, 'prismicStudyMarketsPage.data.prospective_pdfs.document.data');
  const downloadableColumns = get(data, 'prismicStudyMarketsPage.data.downloadable_pdfs.document.data');

  return (
    <Page>
      <Section theme={COLORS.WHITE}>
        <div className="container">
          <Header>{get(data, 'prismicStudyMarketsPage.data.header')}</Header>
          <Paragraph>{get(data, 'prismicStudyMarketsPage.data.description.html')}</Paragraph>
        </div>
        <div className="container">
          <ColumnWrapper className="row">
            {get(featureColumns, 'body', []).map((column, index) => {
              const { icon, header, description, tail_link, link_text } = column.primary;
              return (
                <Column
                  key={index}
                  icon={{
                    url: icon.url,
                    width: get(icon, 'dimensions.width'),
                    height: get(icon, 'dimensions.height'),
                  }}
                  header={header.text}
                  descriptionHTML={description.html}
                  link={tail_link}
                  linkText={link_text}
                  centerContent
                />
              );
            })}
          </ColumnWrapper>
        </div>
      </Section>
      <Section theme={COLORS.LIGHT_GREY}>
        <div className="container">
          <ColumnWrapper className="row">
            {get(prospectiveColumns, 'body', []).map((column, index) => {
              const { icon, header, description, tail_link, link_text } = column.primary;
              return (
                <Column
                  key={index}
                  icon={{
                    url: icon.url,
                    width: COLUMN_ICON_SIZE,
                    height: COLUMN_ICON_SIZE,
                  }}
                  header={header.text}
                  descriptionHTML={description.html}
                  link={tail_link}
                  linkText={link_text}
                />
              );
            })}
          </ColumnWrapper>
        </div>
      </Section>
      <Section theme={COLORS.WHITE}>
        <div className="container">
          <ColumnWrapper className="row">
            {get(downloadableColumns, 'body', []).map((column, index) => {
              const { icon, media_link, media_text, description } = column.primary;
              return (
                <Column
                  key={index}
                  icon={{
                    url: icon.url,
                    width: COLUMN_ICON_SIZE,
                    height: COLUMN_ICON_SIZE,
                  }}
                  iconLink={media_link.url}
                  header={media_text.html}
                  descriptionHTML={description.html}
                />
              );
            })}
          </ColumnWrapper>
        </div>
      </Section>
    </Page>
  );
};

export const query = graphql`
  query {
    prismicStudyMarketsPage {
      data {
        header
        description {
          html
        }
        features {
          id
          ... on PrismicLinkType {
            document {
              ... on PrismicColumns {
                data {
                  body {
                    ... on PrismicColumnsBodyColumn {
                      primary {
                        icon {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                        header {
                          text
                        }
                        description {
                          html
                        }
                        tail_link
                        link_text
                      }
                    }
                  }
                }
              }
            }
          }
        }
        prospective_pdfs {
          id
          ... on PrismicLinkType {
            document {
              ... on PrismicColumns {
                data {
                  body {
                    ... on PrismicColumnsBodyColumn {
                      primary {
                        icon {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                        header {
                          text
                        }
                        description {
                          html
                        }
                        tail_link
                        link_text
                      }
                    }
                  }
                }
              }
            }
          }
        }
        downloadable_pdfs {
          id
          ... on PrismicLinkType {
            document {
              ... on PrismicDownloadColumn {
                data {
                  body {
                    ... on PrismicDownloadColumnBodyDownloadable {
                      primary {
                        icon {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                        description {
                          html
                        }
                        media_link {
                          url
                        }
                        media_text {
                          html
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
